import "./App.css";
import { Box } from "@mui/system";
import { Avatar, Button, Typography, Grid } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import back from "./Assets/Cards/card(0).png";
import allCardImages from "./cards";
import Board from "./Assets/Board.png";
import Win from "./Assets/YouWin.mp4";
import Try from "./Assets/Tryagian.mp4";
import Shaffling from "./Assets/shuffling.mp4";
import CoutDown from "./Assets/CountDown.mp4";
import Loss from "./Assets/YouLose.mp4";
import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import Pusher from "pusher-js";
import toast, { Toaster } from "react-hot-toast";

// Hearders of Realtime Update Table
const columns = [
  { id: "name", label: "Username", minWidth: 100 },
  { id: "amount", label: "Amount", align: "center", minWidth: 60 },
  {
    id: "profit",
    label: "Profit",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const rows1 = [
  // createData('John Doe', 2000, 5000),
  // createData('Jane Smith', 1500, 4000),
  // createData('Michael Johnson', 1800, 4500),
  // createData('Daniel White', 1100, 2800),
  // createData('Sophia Lee', 1300, 3700),
  // createData('James Wilson', 1600, 4200),
  // createData('Olivia Martin', 1400, 3900),
  // createData('Liam Garcia', 1700, 4300),
  // createData('Noah Anderson', 1000, 3200),
  // createData('Isabella Moore', 1900, 4700),
  // createData('Elijah Harris', 2100, 5100),
];

function App() {
  const [shuffledImages, setShuffledImages] = useState([]);

  const [cardZIndex, setCardZIndex] = useState({});
  const [randomGameCard, setRandomGameCard] = useState(null);
  const [showWinVideo, setShowWinVideo] = useState(false); // State to control video display
  const [showShufflingVideo, setShowShufflingVideo] = useState(true); // State to control shuffling video
  const [showCountdownVideo, setShowCountdownVideo] = useState(false); // State to control countdown video
  const [cardsReadyToMove, setCardsReadyToMove] = useState(false); // State to trigger card movement
  const [cardMoves, setCardMoves] = useState({});
  const [cardFlippedAfterMove, setCardFlippedAfterMove] = useState({});
  const [gameCardIndex, setGameCardIndex] = useState(null);
  const [youAmount, setYouAmount] = useState(200);
  const [opponentAmount, setOpponentAmount] = useState(100);
  const [player1Array, setPlayer1Array] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [page1, setPage1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(5);
  const [bettingTime, setBettingTime] = useState(false);
  const [balance, setBalance] = useState(0);

  const url = new URL(window.location.href);
  const user_id = url.searchParams.get("user_id");
  const user_name = url.searchParams.get("user_name");
  const token = url.searchParams.get("token");

  // Fetch the current balance from the backend
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(
          "https://lukzbetbackend.gamingesupport.xyz/api/current-balance",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBalance(response.data.balance);
      } catch (err) {
        setError(err.response ? err.response.data.error : "An error occurred");
      }
    };

    fetchBalance();
  }, []);

  // History Recode Table
  const handleChangePage1 = (event, newPage1) => {
    setPage1(newPage1);
  };

  // Bet Update Table
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Bet Update Table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // History Recode Table
  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(+event.target.value);
    setPage1(0);
  };

  // Delete Card Deck (Shuffled Card Pack)
  const handleDeleteDesck = async () => {
    try {
      const response = await axios.delete(
        "https://cardgamebackend.gamingesupport.xyz/api/delete-card-deck"
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Error deleting card decks:", error);
    }
  };

  // fetch decks From Backend
  const [b1, setB1] = useState([]);

  // Sending the array (Shuffled Card Pack) of cards to the backend
  const handleSubmitDesck = async (e) => {
    try {
      const response = await axios.post(
        "https://cardgamebackend.gamingesupport.xyz/api/store-cards",
        {
          cards: b1[0],
        }
      );
    } catch (error) {
      console.error("Error storing cards:", error);
    }
  };

  // Get Shuffled Card Pack From Backend
  const fetchCardDeck = async () => {
    try {
      const response = await axios.get(
        "https://cardgamebackend.gamingesupport.xyz/api/card-deck"
      );

      if (response.data.cards && response.data.cards.length > 0) {
        shuffledImages.splice(0, 53);
        shuffledImages.push(...response.data.cards);
        setShuffledImages(shuffledImages);
      }
    } catch (error) {
      console.error("Error fetching card deck:", error);
    }
  };

  // Game Card Delete
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        "https://cardgamebackend.gamingesupport.xyz/api/delete-game-card"
      );
      setError(""); // Clear any previous errors
    } catch (err) {
      setError("Failed to delete game cards.");
    }
  };

  // Send Game Card to Backend
  const handleSubmit = async (newIndex) => {
    try {
      const response = await axios.post(
        "https://cardgamebackend.gamingesupport.xyz/api/game-card",
        {
          game_card: newIndex,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Retrieve Game Card
  const [cardIndex, setCardIndex] = useState(null);

  // Get Game Card from Backend
  const fetchCardIndex = async () => {
    try {
      const response = await axios.get(
        "https://cardgamebackend.gamingesupport.xyz/api/get-game-card"
      );
      if (response.data && response.data.data) {
        // Assuming response.data.data contains the game card object
        setCardIndex(response.data.data.card_index); // Set only the card_index from the backend
        setRandomGameCard(allCardImages[response.data.data.card_index]); // Set the corresponding image
      } else {
        // No card in the backend, generate a new one
        const newIndex = Math.floor(Math.random() * 13); // Generate a random index
        handleSubmit(newIndex); // Save the new card to the backend
        setCardIndex(newIndex); // Set the newly generated card index
        setRandomGameCard(allCardImages[newIndex]); // Set the corresponding image
        console.log(`Generated new card: ${newIndex}`);
      }
    } catch (error) {
      setError("Failed to retrieve game card");
      console.error(error);
    }
  };

  useEffect(() => {
    if (cardIndex !== null) {
      // If the card is found in the backend, use it
      setGameCardIndex(cardIndex);
      setRandomGameCard(allCardImages[cardIndex]);
      console.log(`Card from backend: ${cardIndex}`);
    }
  }, [cardIndex]);

  const shuffleArray = (array) => {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  const [playing, setPlaying] = useState(true);
  const [storedIndex, setStoredIndex] = useState(null);
  const sideRef = useRef(null);

  // Bet Real TIme Update
  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("27257c565dad47b9cc64", {
      cluster: "ap2",
      forceTLS: true,
    });

    // Subscribe to the 'bets' channel
    const channel = pusher.subscribe("bets");

    // Bind to the 'BetPlace' event
    channel.bind("BetPlace", (data) => {
      // Handle the incoming bet data
      setBetData((prevData) => [...prevData, data]); // Update the state with new bet data
    });

    // Cleanup on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  // Card Movement and Get winning Decision
  useEffect(() => {
    if (cardsReadyToMove && !showWinVideo) {
      // alert(Number(gameCardIndex) + 10);
      shuffledImages.forEach((_, index) => {
        setTimeout(() => {
          if (showWinVideo) return;
          setCardZIndex((prev) => ({
            ...prev,
            [index]: Math.max(...Object.values(prev), 0) + 1,
          }));

          setCardMoves((prev) => ({ ...prev, [index]: true }));

          setTimeout(() => {
            if (index === 0 || index === 1) {
              setCardFlippedAfterMove((prev) => ({ ...prev, [index]: false }));
            } else {
              setCardFlippedAfterMove((prev) => ({ ...prev, [index]: true }));
            }
          }, 500);

          setTimeout(() => {
            if (
              index > 1 &&
              (shuffledImages[index] === allCardImages[gameCardIndex] ||
                shuffledImages[index] ===
                  allCardImages[Number(gameCardIndex) + 39] ||
                shuffledImages[index] ===
                  allCardImages[Number(gameCardIndex) + 13] ||
                shuffledImages[index] ===
                  allCardImages[Number(gameCardIndex) + 26])
            ) {
              const newSide = index % 2 === 1 ? "Player 02" : "Player 01";
              sideRef.current = newSide; // Update the ref
              console.log(sideRef.current);
              setShowWinVideo(true);
            }
          }, 1000);
        }, 1000 * index);
      });
    }
  }, [cardsReadyToMove, shuffledImages, randomGameCard, showWinVideo]);

  const cards = Array.from({ length: 53 }, (_, index) => (
    <Avatar
      key={index}
      src={cardFlippedAfterMove[index] ? shuffledImages[index] : back}
      alt={`card-${index + 1}`}
      sx={{
        width: "60%",
        height: "auto",
        borderRadius: "0px",
        marginTop: "10px",
        position: "absolute",
        top: "25%",
        left: "25%",
        cursor: "pointer",
        transition: "transform 0.5s ease, z-index 0.5s ease",
        transform: cardMoves[index]
          ? index === 1
            ? "translate(10%,150%)"
            : index === 0
            ? "translateY(150%)"
            : index % 2 === 0
            ? "translate(273%, 220%)"
            : "translate(273%, 60%)"
          : "none",
        zIndex: cardZIndex[index] || 0,
      }}
    />
  ));

  // User input winning Player
  const [activePlayer, setActivePlayer] = useState(null);

  const handleToggle = (player) => {
    setActivePlayer(player);
  };

  // Manage Betting Amount
  const [selectedValue, setSelectedValue] = useState(null); // Manage the selected value
  const [betAmount, setBetAmount] = useState("");

  const handleToggle1 = (value) => {
    setBetAmount(value);
    setSelectedValue(value); // Update the selected value on click
  };

  const [betData, setBetData] = useState([]);
  const [tableBackground, setTableBackground] = useState("");
  const [textcolor, setTextcolor] = useState("");
  const [error, setError] = useState(null);
  const [isBetPlaced, setIsBetPlaced] = useState(false);
  const [data, setData] = useState({
    userCount: 0,
    totalBetAmount: 0,
  });

  // post Bet details To the Pusher
  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("27257c565dad47b9cc64", {
      cluster: "ap2",
    });

    // Subscribe to the 'bets' channel
    const channel = pusher.subscribe("bets");

    // Function to fetch user count and total bet amount
    const fetchUserCountAndBetAmount = async () => {
      try {
        const response = await axios.get(
          "https://cardgamebackend.gamingesupport.xyz/api/user-count"
        );
        const { "User Count": userCount, "Total Bet Amount": totalBetAmount } =
          response.data;

        // Update state with the new data
        setData({
          userCount,
          totalBetAmount,
        });
      } catch (err) {
        setError("Failed to fetch updated data");
      }
    };

    // Listen for the 'BetPlace' event and update the state with the bet details
    channel.bind("App\\Events\\BetPlace", (data) => {
      console.log("New bet data received:", data);
      setBetData((prevData) => [data, ...prevData]);
    });

    // Polling every 5 seconds (5000 milliseconds) to update user count and total bet amount
    const interval = setInterval(fetchUserCountAndBetAmount, 5000);

    // Cleanup the Pusher subscription and the polling interval on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      clearInterval(interval);
    };
  }, []);

  // Handle placing a bet
  const handlePlaceBet = () => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(
          "https://lukzbetbackend.gamingesupport.xyz/api/current-balance",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBalance(response.data.balance);
        if (response.data.balance <= betAmount) {
          toast.error("Insuficent balance!");
        } else {
          const newBet = {
            winning_player: activePlayer,
            bet_amount: betAmount,
            wining_profit: betAmount * 2,
            id: betData.length + 1,
          };

          axios
            .post(
              `https://cardgamebackend.gamingesupport.xyz/api/place-bet/?token=YOUR_TOKEN&user_id=${user_id}&user_name=${user_name}`,
              newBet
            )
            .then((response) => {
              setBetAmount("");
              setIsBetPlaced(true);
            })
            .catch((error) => {
              console.error("There was an error placing the bet!", error);
            });

          toast.success("Bet Placed successfully!");
        }
      } catch (err) {
        setError(err.response ? err.response.data.error : "An error occurred");
      }
    };
    fetchBalance();
  };

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  // Send Winner To the Backend
  const updateWinner = async (winner) => {
    try {
      const response = await axios.get(
        "https://cardgamebackend.gamingesupport.xyz/api/winner",
        {
          params: {
            winning_player: winner,
          },
        }
      );
      console.log(response.data.message);
    } catch (error) {
      console.error("Error updating winner:", error.response.data);
    }
  };

  function colorChange() {
    if (showWinVideo && activePlayer !== sideRef.current) {
      setTableBackground("rgba(0, 255, 0, 0.2)");
      setTextcolor("green");
    } else {
      setTableBackground("rgba(255, 0, 0, 0.2)");
      setTextcolor("red");
    }
  }

  // Reloading
  function handleReload() {
    window.location.reload();
  }

  return (
    <Box>
      <Toaster />
      <Box
        sx={{
          "@scale.go": "1",
          display: playing == true ? "flex" : "none",
          justifyContent: "center",
          height: "100vh",
          justifyContent: "space-around",
          backgroundColor: "#020C25",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            width: "30%",
            height: "90%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Paper
            sx={{
              width: "90%",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 1)",
              height: "105%",
            }}
          >
            <TableContainer sx={{ maxHeight: "95%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: "bold" }}
                        variant="h6"
                      >
                        Number of Players
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: "bold" }}
                        variant="h6"
                      >
                        {data.userCount}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" colSpan={3}>
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: "bold" }}
                        variant="h6"
                      >
                        Total Amount
                      </Typography>
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: "bold" }}
                        variant="h6"
                      >
                        LKR {data.totalBetAmount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          top: 57,
                          minWidth: column.minWidth,
                          backgroundColor: "#061D47",
                          color: "white",
                          fontSize: "80%",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {betData.map((bet, index) => {
                    // Determine the background and text color dynamically
                    const tableBackground = showWinVideo
                      ? bet.winning_player !== sideRef.current
                        ? "rgba(255, 0, 0, 0.2)" // Red background for loss
                        : "rgba(0, 255, 0, 0.2)" // Green background for win
                      : "white"; // Default white background when showWinVideo is false

                    const textcolor = showWinVideo
                      ? bet.winning_player === sideRef.current
                        ? "green" // Green text color for win
                        : "red" // Red text color for loss
                      : "black"; // Default black text color when showWinVideo is false

                    return (
                      <TableRow
                        key={index}
                        sx={{ backgroundColor: tableBackground }}
                      >
                        <TableCell align="start" sx={{ color: textcolor }}>
                          {bet.user_name}
                        </TableCell>
                        <TableCell align="center" sx={{ color: textcolor }}>
                          {bet.bet_amount}
                        </TableCell>
                        <TableCell align="center" sx={{ color: textcolor }}>
                          {bet.bet_amount * 2}
                        </TableCell>
                        {/* Add more table columns based on your Bet_Detail data */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={betData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#061D47",
            width: "40%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "30%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-200px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginTop: "10%",
                  textAlign: "center",
                  position: "absolute",
                }}
              >
                Card Pack
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  marginTop: "20%",
                }}
              >
                {cards}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100% ",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginTop: "10%",
                  textAlign: "center",
                }}
              >
                Trial Cards
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {/* Empty box for trial cards */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "120%",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginTop: "10px",
                  textAlign: "center",
                }}
              >
                Game Card
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Avatar
                  src={allCardImages[gameCardIndex]}
                  sx={{
                    width: "60%",
                    marginLeft: "5%",
                    height: "auto",
                    borderRadius: "0px",
                    marginTop: "10px",
                    position: "absolute",
                    top: "25%",
                    left: "20%",
                  }}
                />
                {/* <Avatar src={allCardImages[gameCardIndex+13]} sx={{ width: "60%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "28%" }} />
                <Avatar src={allCardImages[gameCardIndex+26]} sx={{ width: "60%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "36%" }} />
                <Avatar src={allCardImages[gameCardIndex+39]} sx={{ width: "60%", height: "auto", borderRadius: "0px", marginTop: "10px", position: "absolute", top: "25%", left: "44%" }} /> */}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "70%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "rgba(2,12,37, 0.5)",
                padding: "10px",
                borderRadius: "10px",
                width: "30%",
                position: "absolute",
                top: "2vh",
                marginTop: {
                  xl: "0px",
                  lg: "0px",
                  md: "150px",
                  sm: "150px",
                  xs: "150px",
                },
              }}
            >
              Player 2
            </Typography>
            <Box
              sx={{
                height: "100vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                src={Board}
                sx={{
                  width: {
                    xl: "auto",
                    lg: "auto",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  height: {
                    xl: "90%",
                    lg: "90%",
                    md: "45%",
                    sm: "45%",
                    xs: "45%",
                  },
                  borderRadius: "0px",
                  marginTop: {
                    xl: "0px",
                    lg: "0px",
                    md: "-100px",
                    sm: "-100px",
                    xs: "-100px",
                  },
                }}
              />
            </Box>
            <Typography
              sx={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "rgba(2,12,37, 0.5)",
                padding: "10px",
                borderRadius: "10px",
                width: "30%",
                position: "absolute",
                top: "90vh",
                marginTop: {
                  xl: "0px",
                  lg: "0px",
                  md: "-200px",
                  sm: "-200px",
                  xs: "-200px",
                },
              }}
            >
              Player 1
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "30%",
            height: "93%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            borderRadius: "0px",
          }}
        >
          <Box sx={{ width: "90%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: "white",
                  fontSize: {
                    xl: "25px",
                    lg: "25px",
                    md: "18px",
                    sm: "15px",
                    xs: "15px",
                  },
                }}
              >
                Current Balance : {balance}
              </Typography>
              <Button
                href="https://lukzbet.com/"
                sx={{
                  marginBottom: "20px",
                  backgroundColor: "#061D47",
                  color: "white",
                  width: "20px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#e8eaed"
                >
                  <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z" />
                </svg>
              </Button>
            </Box>

            {
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "40%",
                  backgroundColor: "white",
                  borderRadius: "5px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 1)",
                }}
              >
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  Winning Player
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={() => handleToggle("Player 01")}
                    sx={{
                      fontSize: "12px",
                      color: activePlayer === "Player 01" ? "white" : "black",
                      textAlign: "start",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      backgroundColor:
                        activePlayer === "Player 01"
                          ? "rgba(6,29,71, 1)"
                          : "rgba(6,29,71, 0.5)",
                      textTransform: "none",
                      borderRadius: "none",
                      width: "40%",
                    }}
                  >
                    Player 01
                  </Button>
                  <Button
                    onClick={() => handleToggle("Player 02")}
                    sx={{
                      fontSize: "12px",
                      color: activePlayer === "Player 02" ? "white" : "black",
                      textAlign: "start",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      backgroundColor:
                        activePlayer === "Player 02"
                          ? "rgba(6,29,71, 1)"
                          : "rgba(6,29,71, 0.5)",
                      textTransform: "none",
                      borderRadius: "none",
                      width: "40%",
                    }}
                  >
                    Player 02
                  </Button>
                </Box>

                <input
                  type="number"
                  placeholder="Betting Amount"
                  value={betAmount} // Bind input field to state
                  onChange={(e) => setBetAmount(e.target.value)} // Update state when input changes
                  style={{
                    margin: "10px 0px",
                    width: "85%",
                    border: "1px solid #00712D",
                    borderRadius: "5px",
                    padding: "5px",
                    outline: "none",
                  }}
                />
                <Box sx={{ width: "90%", marginTop: "-5px" }}>
                  <Grid container spacing={0.2}>
                    {["100", "200", "300", "400", "500", "600"].map(
                      (value, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={{
                              backgroundColor:
                                selectedValue === value
                                  ? "#061D47"
                                  : "rgba(6,29,71, 0.5)", // Change color based on selection
                              textTransform: "none",
                              borderRadius: "none",
                              fontSize: "10px",
                              height: "20px",
                            }}
                            onClick={() => handleToggle1(value)} // Update input value on clic
                          >
                            {value}
                          </Button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
                <Button
                  onClick={handlePlaceBet}
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: isBetPlaced ? "#061D47" : "#061D47",
                    textTransform: "none",
                    borderRadius: "none",
                    marginTop: "10px",
                    width: "90%",
                    fontSize: "12px",
                    height: "30px",
                  }}
                  disabled={isBetPlaced || !bettingTime}
                >
                  {isBetPlaced ? "Placed" : "Place Bet"}
                </Button>
              </Box>
            }

            {/* History Records Table */}
            <Paper
              sx={{
                width: "100%",
                height: "55%",
                marginTop: "10px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 1)",
              }}
            >
              <TableContainer sx={{ maxHeight: "90%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            backgroundColor: "#061D47",
                            color: "white",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows1.length > 0 ? (
                      rows1
                        .slice(
                          page1 * rowsPerPage1,
                          page1 * rowsPerPage1 + rowsPerPage1
                        )
                        .map((row) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  style={{ fontSize: "60%" }}
                                  align={column.align}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={columns.length}
                          align="center"
                          style={{ fontSize: "2rem", height: "35vh" }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ opacity: "0.5", fontSize: "12px" }}
                          >
                            You have no records
                          </Typography>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="100px"
                            viewBox="0 -960 960 960"
                            width="100px"
                            fill="#e8eaed"
                          >
                            <path d="M200-800v241-1 400-640 200-200Zm80 400h140q9-23 22-43t30-37H280v80Zm0 160h127q-5-20-6.5-40t.5-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v100q-19-8-39-12.5t-41-6.5v-41H480v-200H200v640h241q16 24 36 44.5T521-80H200Zm460-120q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM864-40 756-148q-21 14-45.5 21t-50.5 7q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7 50.5T812-204L920-96l-56 56Z" />
                          </svg>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={rows1.length}
                rowsPerPage={rowsPerPage1}
                page={page1}
                onPageChange={handleChangePage1}
                onRowsPerPageChange={handleChangeRowsPerPage1}
              />
            </Paper>
          </Box>
        </Box>

        {/* Shuffling Video */}
        {showShufflingVideo && (
          <Box
            sx={{
              position: "fixed",
              width: "40%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "rgba(6,29,71, 1)",
            }}
          >
            <video
              src={Shaffling}
              autoPlay
              muted
              onPlay={() => {
                setPlaying(true);
                fetchCardIndex();
              }}
              onEnded={() => {
                setShowShufflingVideo(false);
                setTimeout(() => {
                  setShowCountdownVideo(true);
                }, 5000);
              }}
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        )}

        {/* Countdown Video */}
        {showCountdownVideo && (
          <Box
            sx={{
              position: "fixed",
              width: "40%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "rgba(6,29,71, 1)",
            }}
          >
            <video
              src={Win}
              autoPlay
              muted
              onPlay={() => {
                setBettingTime(true);
              }}
              onEnded={async () => {
                let newYouAmount = 0;
                let newOpponentAmount = 1;

                fetchCardDeck();

                try {
                  const response = await axios.get(
                    "https://cardgamebackend.gamingesupport.xyz/api/player-total-bet"
                  );

                  newYouAmount = response.data.Player_One;
                  newOpponentAmount = response.data.Player_Two;

                  setYouAmount(newYouAmount);
                  setOpponentAmount(newOpponentAmount);

                  console.log("Fetched youAmount:", newYouAmount);
                  console.log("Fetched opponentAmount:", newOpponentAmount);
                } catch (error) {
                  console.error("Error fetching player bets:", error);
                }

                if (shuffledImages.length == 0) {
                  // If shuffledImages doesn't exist in local storage, generate it
                  const newArray = [...allCardImages];

                  player1Array.push(newArray[gameCardIndex]);
                  player1Array.push(newArray[gameCardIndex + 13]);
                  player1Array.push(newArray[gameCardIndex + 26]);
                  player1Array.push(newArray[gameCardIndex + 39]);

                  newArray.splice(gameCardIndex, 1);
                  newArray.splice(gameCardIndex + 12, 1);
                  newArray.splice(gameCardIndex + 24, 1);
                  newArray.splice(gameCardIndex + 36, 1);

                  for (let i = 0; i < 22; i++) {
                    player1Array.push(newArray[0]);
                    newArray.splice(0, 1);
                  }

                  let shuffledPlayer1Array = shuffleArray([...player1Array]);
                  let shuffledNewArray = shuffleArray([...newArray]);

                  const totalElements =
                    shuffledNewArray.length + shuffledPlayer1Array.length;

                  if (newYouAmount > newOpponentAmount) {
                    for (let i = 0; i < totalElements; i++) {
                      if (i % 2 === 0) {
                        if (shuffledNewArray.length > 0) {
                          shuffledImages.push(shuffledNewArray.shift());
                        }
                      } else {
                        if (shuffledPlayer1Array.length > 0) {
                          shuffledImages.push(shuffledPlayer1Array.shift());
                        }
                      }
                    }
                  } else {
                    for (let i = 0; i < totalElements; i++) {
                      if (i % 2 !== 0) {
                        if (shuffledNewArray.length > 0) {
                          shuffledImages.push(shuffledNewArray.shift());
                        }
                      } else {
                        if (shuffledPlayer1Array.length > 0) {
                          shuffledImages.push(shuffledPlayer1Array.shift());
                        }
                      }
                    }
                  }
                  b1.push(shuffledImages);
                  handleSubmitDesck();
                  fetchCardDeck();
                  // alert("card not exist")
                } else {
                  // alert(shuffledImages[0].length)
                  // let c1 = [];
                  // c1.push(shuffledImages[0]);
                  // shuffledImages.splice(0, 1);
                  // shuffledImages.push(c1);
                  // alert(shuffledImages.length)
                  // setStoredShuffledImages(shuffledImages[0][0])
                  // storedShuffledImages.push(shuffledImages[0][0]);
                  // storedShuffledImages.push(storedShuffledImages[0][0]);
                  // alert(shuffledImages.length)
                  // alert(storedShuffledImages.length)
                  // alert("card exist")
                  // alert(shuffledImages)
                }

                // if (!storedShuffledImages || storedShuffledImages.length < 3) {

                // // Store the generated shuffledImages in local storage
                // //localStorage.setItem('shuffledImages', JSON.stringify(shuffledImages));
                // //alert(shuffledImages)

                // }

                setBettingTime(false);
                setShowCountdownVideo(false);
                setCardsReadyToMove(true);
              }}
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        )}

        {/* Win or Loss Video */}

        {showWinVideo && (
          <Box
            sx={{
              position: "fixed",
              width: "40%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "rgba(6,29,71, 1)",
            }}
          >
            {activePlayer !== null ? (
              <video
                src={activePlayer === sideRef.current ? Win : Loss}
                autoPlay
                muted
                onPlay={() => {
                  setCardsReadyToMove(true);
                  updateWinner(sideRef.current);
                  colorChange();
                  //alert(storedIndex)
                  setTimeout(() => {
                    handleDelete();
                    handleDeleteDesck();
                  }, 5000);
                  // setStoredIndex("");
                  //alert(storedIndex)
                }}
                onEnded={() => {
                  setStoredIndex("");
                  setShowWinVideo(false);
                  setActivePlayer("");
                  setBettingTime(false);

                  // Call handleReload here to refresh the
                  // localStorage.clear();

                  handleReload();
                }}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <Box fontSize={"50px"} sx={{ color: "white" }}>
                <video
                  src={Try}
                  autoPlay
                  muted
                  onPlay={() => {
                    setCardsReadyToMove(true);
                    updateWinner(sideRef.current);
                    colorChange();
                    setTimeout(() => {
                      handleDelete();
                      handleDeleteDesck();
                    }, 5000);
                  }}
                  onEnded={() => {
                    setShowWinVideo(false);
                    setActivePlayer("");
                    setBettingTime(false);
                    localStorage.clear();

                    // Call handleReload here to refresh the page
                    handleReload();
                  }}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      {/* <Box sx={{display: playing==true ? "none":"flex"}}>Loading.....</Box> / */}
    </Box>
  );
}

export default App;

///////
